import _ from 'lodash';
// dayjs
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const addBlankToSelect = function(arr){
  arr = _.cloneDeep(arr)
  arr.unshift({name: "--- 選んでください ---", id: null}) 
  return arr
}

const paymentStatus = function(payment){
  if(payment.status === "canceled"){
    // 赤伝票
    if(payment.group.pay_called_at === null){
      // 赤伝票修正済み
      return "canceled_fix_done"
    }else{
      // 赤伝票
      return "canceled_only"
    }
  }else if(payment.status === "fix_paied"){
    // 修正伝票
    return "fix_paied"
  }else{
    return "paied"
  }
}

let getInvertedColor = function(colorCode) {
  try{
    // 16進数を変換
    const red = parseInt(colorCode.substr(1,2), 16);
    const green = parseInt(colorCode.substr(3,2), 16);
    const blue = parseInt(colorCode.substr(5,2), 16);
    // 明るさを判定
    const luminance = ( red*299 + green*587 + blue*114 ) / 2550;
    // 明るいときは黒、暗いときは白を返す（60の基準値は調整する）
    if (luminance > 60) {
      return "#000000";
    } else {
      return "#ffffff";
    }
  }catch(e){
    return "#000000";
  }
}

let scrollToTop = function(){
  document.getElementById('__layout').scrollIntoView({
    behavior: 'smooth',
    block: 'start'
  });
}

let getZoomMessage = function(lesson){
  let message = 
`トピック: ${lesson.zoom_settings.topic}
時間: ${dayjs(lesson.zoom_settings.start_time).format('YYYY-MM-DD HH:mm:ss')} 大阪、札幌、東京

Zoomミーティングに参加する
${lesson.zoom_settings.join_url}

ミーティングID: ${lesson.zoom_settings.id}
パスコード: ${lesson.zoom_settings.password}
`
  return message
}

let comparisonHm = function(hm_1, diff, hm_2){
  let time_1 = dayjs(`2023/1/1 ${hm_1}`)
  let time_2 = dayjs(`2023/1/1 ${hm_2}`)
  if(diff === ">") return time_1.isAfter(time_2)
  if(diff === ">=") return time_1.isSameOrAfter(time_2)
  if(diff === "==") return time_1.isSame(time_2)
  if(diff === "<=") return time_1.isSameOrBefore(time_2)
  if(diff === "<") return time_1.isBefore(time_2)
}

// color
// RGBからHSBに変換する関数
function rgbToHsb(r, g, b) {
  r /= 255, g /= 255, b /= 255;
  let max = Math.max(r, g, b), min = Math.min(r, g, b);
  let h, s, v = max;

  let d = max - min;
  s = max == 0 ? 0 : d / max;

  if(max == min){
      h = 0;
  } else {
      switch(max){
          case r: h = (g - b) / d + (g < b ? 6 : 0); break;
          case g: h = (b - r) / d + 2; break;
          case b: h = (r - g) / d + 4; break;
      }
      h /= 6;
  }

  return [Math.round(h * 360), Math.round(s * 100), Math.round(v * 100)];
}

// HSBからRGBに変換する関数
function hsbToRgb(h, s, v) {
  let r, g, b, i, f, p, q, t;
  s /= 100, v /= 100;
  h /= 60;
  i = Math.floor(h);
  f = h - i;
  p = v * (1 - s);
  q = v * (1 - s * f);
  t = v * (1 - s * (1 - f));
  switch (i % 6) {
      case 0: r = v, g = t, b = p; break;
      case 1: r = q, g = v, b = p; break;
      case 2: r = p, g = v, b = t; break;
      case 3: r = p, g = q, b = v; break;
      case 4: r = t, g = p, b = v; break;
      case 5: r = v, g = p, b = q; break;
  }
  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}

// RGBをHEXに変換する関数
function rgbToHex(r, g, b) {
  return "#" + [r, g, b].map(x => {
      const hex = x.toString(16);
      return hex.length === 1 ? '0' + hex : hex;
  }).join('');
}

// HEXカラーコードを受け取り、指定された変更を加えてHEXに戻す関数
function adjustColorHex(hex) {
  // HEXからRGBへ変換
  let r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);

  // RGBからHSBへ変換
  let [hsb_h, hsb_s, hsb_b] = rgbToHsb(r, g, b);

  // HSB値を調整
  hsb_h = (hsb_h + 10) % 360;
  hsb_s = Math.max(0, Math.min(100, hsb_s - 20));
  hsb_b = Math.max(0, Math.min(100, hsb_b + 10));

  // 調整されたHSBをRGBへ変換
  [r, g, b] = hsbToRgb(hsb_h, hsb_s, hsb_b);

  // RGBをHEXへ変換
  return rgbToHex(r, g, b);
}

export {
  addBlankToSelect,
  paymentStatus,
  getInvertedColor,
  scrollToTop,
  getZoomMessage,
  comparisonHm,
  adjustColorHex,
}