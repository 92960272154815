import _ from 'lodash';
import dayjs from 'dayjs';

export let form_config = {
  headers: {
    "content-type": "multipart/form-data",
  }
}  

export let client = {
  id: null,
  name: null,
  email: null,
  phone_number: null,
  introduction_code: null,
  tracking_code: null,
  inner_memo: null,
}

export let client_trend = {
  date: null,
  weight: null,
  fat_rate: null,
  muscle_weight: null,
  fat_weight: null,
  water_weight: null,
  sleep_from: null,
  sleep_to: null,
  sleep_hour: null,
  condition: null,
  alcohol: null,
  breakfast_memo: null,
  lunch_memo: null,
  dinner_memo: null,
  other_memo: null,
  breakfast_reply: null,
  lunch_reply: null,
  dinner_reply: null,
  other_reply: null,
  summary_reply: null,
  client_rating: null,
  company_rating: null,
  daialy: null,
  data: null,

  manage_food: null,
  no_alcohol: null,
  do_walkiing: null,
  do_toilet: null,
  food_doc_ids: [],
}

export let goal_term = {
  start_staff_id: null,
  end_staff_id: null,
  begining_lesson_id: null,
  end_lesson_id: null,
  begining_bodyscan_id: null,
  end_bodyscan_id: null,
  start_front_img: null,
  start_side_img: null,
  start_back_img: null,
  end_front_img: null,
  end_side_img: null,
  end_back_img: null,
  title: null,
  memo: null,
  start_comment: null,
  end_comment: null,
  start_date: null,
  goal_date: null,
  datas: {
    start: {
      weight: null,
      fat_rate: null,
      bmi: null,
      neck_girth_length: null,
      upper_arm_girth_length: null,
      bust_girth_length: null,
      waist_girth_length: null,
      hip_girth_length: null,
      thigh_girth_length: null,
      max_weight_deadlift: null,
      max_weight_squat: null,
      max_weight_bench_press: null,
    },
    end: {
      weight: null,
      fat_rate: null,
      bmi: null,
      neck_girth_length: null,
      upper_arm_girth_length: null,
      bust_girth_length: null,
      waist_girth_length: null,
      hip_girth_length: null,
      thigh_girth_length: null,
      max_weight_deadlift: null,
      max_weight_squat: null,
      max_weight_bench_press: null,
    },
    goal: {
      weight: null,
      fat_rate: null,
      bmi: null,
      neck_girth_length: null,
      upper_arm_girth_length: null,
      bust_girth_length: null,
      waist_girth_length: null,
      hip_girth_length: null,
      thigh_girth_length: null,
      max_weight_deadlift: null,
      max_weight_squat: null,
      max_weight_bench_press: null,
    },
  }
}

export let cl_payment = {
  id: null,
  payment_type: "item",
  name: null,
  price_subtotal: 0,
  spend_point: 0,
  disacount_price_sum: 0,
  price_sum: 0,
  tax_sum: 0,
  normal_tax_sum: 0,
  keigen_tax_sum: 0,
  azukari_price: 0,
  otsuri_price: 0,

  // ticket
  description: null,
  ticket_number: 1,
  stock_number: 1,
  use_from: dayjs().format("YYYY-MM-DD"),
  use_to: dayjs().add(60, "d").format("YYYY-MM-DD"),
  expire_days: 60,
  able_menu_ids: [],
}

export let doc = {
  sort_id: -1,
  isShow: true,
  id: null,
  lesson_id: null,
  task_id: null,
  doc_type: null,
  file_type: null,
  file_name: null,
  origin_file_name: null,
  url: null,
  img: null,
  file: null,
}

export let lesson = {
  date: null,
  weight: null,
  fat_rate: null,
  muscle_weight: null,
  fat_weight: null,
  water_weight: null,
  basal_metabolism: null,
  bmi: null,
  neck_girth_length: null,
  upper_arm_girth_length: null,
  bust_girth_length: null,
  waist_girth_length: null,
  hip_girth_length: null,
  thigh_girth_length: null,
  max_weight_bench_press: null,
  max_weight_squat: null,
  max_weight_deadlift: null,
  training_events: {
    event_sets: [],
  },
  // もうこれ使わなくなった、そんなこと無い作成時にはつかう
  training_docs: {
    ids: [],
    video_ids: [],
  },
  videos: [],
  memo: null,
  inner_memo: null,
}

// 種目のトレ履歴
export let training_set = {
  weight: null,
  count: 10,
  is_giri: 0,
  is_support: 0,
  support_count: null,
  memo: null,
  event: {
    weight_unit: 'kg',
    count_unit: '回'
  }
}

export let training_list = {
  uid: null,
  sort: 1,
  bookmark: false,
  name: '',
  description: '',
  training_events: {
    event_sets: []
  }
}

// 個人のトレ記録
export let client_training_event = {
  uid: null,
  status: null,
  favarite_type: '',
  doc_ids: '',
  memo: '',
  inner_memo: '',
  max_weight: null,
  max_sum_weight: null,
  count_at_gym: null,
  count_at_self: null,
  latest_at_gym: null,
  latest_by_self: null,
  histories: []
}

// 個人のトレ記録
export let training_event_log = {
  event_id: null,
  inner_memo: null,
  memo: "",
  cl_memo: "",
  status: "never",
  // like_count: 0,
  // intresting_count: 0,
  max_weight: null,
  latest_at_gym: null,
  latest_at_self: null,
  histories: [],
  // histories: [{
  //   lesson_id: null,
  //   lesson_uid: null,
  //   client_trend_id: null,
  //   client_trend_uid: null,
  //   event_set: [],
  //   updated_at: null,
  // }],
}

export let training_event_status = [
  { text: '未設定', value: "never" },
  { text: '苦手', value: "not_good" },
  { text: '上手', value: "good" },
  { text: '非常に上手', value: "very_good" },
  { text: '完了', value: "done" },
]

export let taxRateOptions = [
  { text: '10%', value: 10 },
  { text: '8%', value: 8 },
  // { text: '0%', value: 0 },
]

export let sexOptions = [
  // { value: null, text: '選んでください',  },
  { value: "male", text: '男性' },
  { value: "female", text: '女性' },
  // { value: "other", text: 'その他' },
]

export let eventHeadlines = [
  { type: 'all', label: '全て' },
  { type: 'ア', label: 'ア', active: true },
  { type: 'イ', label: 'イ', active: true },
  { type: 'ウ', label: 'ウ', active: true },
  { type: 'エ', label: 'エ', active: true },
  { type: 'オ', label: 'オ', active: true },
  { type: 'カ', label: 'カ', active: true },
  { type: 'キ', label: 'キ', active: true },
  { type: 'ク', label: 'ク', active: true },
  { type: 'ケ', label: 'ケ', active: true },
  { type: 'コ', label: 'コ', active: true },
  { type: 'サ', label: 'サ', active: true },
  { type: 'シ', label: 'シ', active: true },
  { type: 'ス', label: 'ス', active: true },
  { type: 'セ', label: 'セ', active: true },
  { type: 'ソ', label: 'ソ', active: true },
  { type: 'タ', label: 'タ', active: true },
  { type: 'チ', label: 'チ', active: true },
  { type: 'ツ', label: 'ツ', active: true },
  { type: 'テ', label: 'テ', active: true },
  { type: 'ト', label: 'ト', active: true },
  { type: 'ナ', label: 'ナ', active: true },
  { type: 'ニ', label: 'ニ', active: true },
  { type: 'ヌ', label: 'ヌ', active: true },
  { type: 'ネ', label: 'ネ', active: true },
  { type: 'ノ', label: 'ノ', active: true },
  { type: 'ハ', label: 'ハ', active: true },
  { type: 'ヒ', label: 'ヒ', active: true },
  { type: 'フ', label: 'フ', active: true },
  { type: 'ヘ', label: 'ヘ', active: true },
  { type: 'ホ', label: 'ホ', active: true },
  { type: 'マ', label: 'マ', active: true },
  { type: 'ミ', label: 'ミ', active: true },
  { type: 'ム', label: 'ム', active: true },
  { type: 'メ', label: 'メ', active: true },
  { type: 'モ', label: 'モ', active: true },
  { type: 'ヤ', label: 'ヤ', active: true },
  { type: 'ユ', label: 'ユ', active: true },
  { type: 'ヨ', label: 'ヨ', active: true },
  { type: 'ラ', label: 'ラ', active: true },
  { type: 'リ', label: 'リ', active: true },
  { type: 'ル', label: 'ル', active: true },
  { type: 'レ', label: 'レ', active: true },
  { type: 'ロ', label: 'ロ', active: true },
  { type: 'ワ', label: 'ワ', active: true },
  { type: 'other', label: 'その他', active: true }
]
